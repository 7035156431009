import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'reflexbox';
import { useModal } from '../../models/global/withModal';
import { COLOR } from '../../constants/common';
import { Absolute } from '../../ui/box/Absolute';
import { CloseIcon } from '../../ui/icons/CloseIcon';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(112, 112, 112, 0.86);
  z-index: 1500;
  left: 0;
  top: 0;
  ${({ open }) => (open ? 'display: flex;' : '')}
`;

const Content = styled(Box)`
  background: ${COLOR.white};
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 69vw;
  height: 77vh;
  margin: auto;
  position: relative;
  
  ${({ bottom }) =>
    bottom && css`
      bottom: ${bottom};
    `}

  ${({ auto }) =>
    auto &&
    css`
      height: auto;
    `}
  
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 492px;
      height: auto;
      min-height: 100px;
    `}
`;

const CloseWrapper = styled(Absolute)`
  top: 16px;
  right: 12px;
  cursor: pointer;
  z-index: 2;

  ${({ small }) =>
    small &&
    css`
      top: 5px;
      right: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
    `}
`;

export const Modal = ({ id, children, small, auto, bottom, autoWidth }) => {
  const { open, closeModal, initData } = useModal();
  const isOpen = id === open;
  return (
    <Wrapper open={isOpen}>
      {isOpen && (
        <Content onClick={(e) => e.stopPropagation()} small={small} auto={auto} bottom={bottom} autoWidth={autoWidth}>
          <CloseWrapper onClick={closeModal} small={small || autoWidth}>
            <CloseIcon />
          </CloseWrapper>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              initData,
            });
          })}
        </Content>
      )}
    </Wrapper>
  );
};
