import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { COLOR } from '../../constants/common';
import { Relative } from '../box/Relative';

export const Tools = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.3s ease;
  align-items: center;
  padding: 20px;
  background: ${COLOR.white2};

  &:hover {
    opacity: 1;
  }

  & > div,
  & > a {
    display: inline-table;
    margin-right: 40px;

    &:hover {
      .active-change {
        fill: ${COLOR.brownishGrey} !important;
      }

      .active-change-stroke {
        stroke: ${COLOR.brownishGrey} !important;
      }
    }
  }
`;

export const ToolsWrapper = styled(Relative)`
  height: 100%;
  padding: 7px 0;
  margin: -1px 8px 0 0 !important;
  min-width: 275px !important;
  cursor: ${props => props.pointer ? 'pointer': 'default'};
`;
