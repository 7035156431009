import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';

import { COLOR } from '../../constants/common';
import { useUser } from '../../../models/users/withUser'; // TODO:NEW Remove one "/../"
import { VoxMIcon } from '../../ui/icons/VoxMIcon';
import { MenuIcon } from '../../ui/icons/MenuIcon';
import { Text } from '../../ui/text/Text';
import { Pointer } from '../../ui/box/Pointer';
import { useTranslation } from 'react-i18next';
import { RoundIcon } from '../../ui/icons/RoundIcon';
import { CreateIcon } from '../../ui/icons/CreateIcon';
import { WithMenu } from '../../models/menu/withMenu';
import { useModal } from '../../models/global/withModal';
import { IconButton } from '../../ui/button/IconButton';
import { useHistory } from 'react-router-dom';
import { channelsLinks, mainLinks } from '../../constants/sidebar/links';
import { isToDisplay } from '../../utils/utils';

export const menuHeight = '64px';

const MenuWrapper = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background: ${COLOR.white};
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 15px 20px 14px 26px;
  height: ${menuHeight};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  ${({ isSuperAdmin }) =>
    isSuperAdmin
      ? css`
          background: ${COLOR.adminModeDark};
          path {
            fill: ${COLOR.white};
          }
        `
      : ''};
`;

export const Menu = () => {
  const { user, inSuperAdminMode } = useUser();
  const { toggleSidebar } = WithMenu();
  const { openModal } = useModal();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <MenuWrapper isSuperAdmin={inSuperAdminMode}>
      <Pointer mr={'26px'} mt={'10px'} onClick={toggleSidebar}>
        <MenuIcon />
      </Pointer>
      <Pointer
        display={'flex'}
        alignItems={'center'}
        flex={1}
        onClick={() =>
          history.replace(
            inSuperAdminMode ? channelsLinks[0].path : mainLinks[0].path
          )
        }
      >
        <VoxMIcon />
        <Text
          ml={'17px'}
          size={'h2'}
          mt={'3px'}
          capital
          theme={inSuperAdminMode ? 'white' : undefined}
        >
          {t('Interaction Manager')}
          {inSuperAdminMode ? ` - ${t('Admin')}` : ''}
        </Text>
      </Pointer>

      {inSuperAdminMode ? (
        <IconButton
          onClick={() => history.push('/channels/new')}
          theme={'white'}
        >
          <CreateIcon color={COLOR.waterBlue} />
          <Text>{t('Create a media')}</Text>
        </IconButton>
      ) : (
        <>
          {isToDisplay(user, "create-interaction") &&
            <IconButton onClick={() => openModal('interaction')}>
              <CreateIcon />
              <Text>{t('Create an interaction')}</Text>
            </IconButton>
          }
        </>
      )}
      {
        <Pointer onClick={() => history.push(`/users/${user?.id}`)}>
          <RoundIcon src={user?.image_url} alt={''} size={32} />
        </Pointer>
      }
    </MenuWrapper>
  );
};
