import React from 'react';
import { COLOR } from '../../constants/common';

export const WarningIcon = ({ active, color = COLOR.waterBlue }) => (
  <svg
    height="21"
    viewBox="0 0 21 21"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    {active ? (
      <g transform="translate(-408 -265)">
        <path
          d="m422.142 265h-8.284l-5.858 5.858v8.284l5.858 5.858h8.284l5.858-5.858v-8.284z"
          fill={color}
        />
        <g fill="#fff">
          <path
            d="m574.882 332.218a1.308 1.308 0 1 1 2.616 0v6.541a1.308 1.308 0 1 1 -2.616 0z"
            transform="translate(-158.19 -62.477)"
          />
          <circle cx="418" cy="280.207" r="1.308" />
        </g>
      </g>
    ) : (
      <g fill="none" stroke={color} transform="translate(-407.5 -264.5)">
        <path d="m422.142 265h-8.284l-5.858 5.858v8.284l5.858 5.858h8.284l5.858-5.858v-8.284z" />
        <path
          d="m574.882 332.218a1.308 1.308 0 1 1 2.616 0v6.541a1.308 1.308 0 1 1 -2.616 0z"
          transform="translate(-158.19 -62.477)"
        />
        <circle cx="418" cy="280.207" r="1.308" />
      </g>
    )}
  </svg>
);
