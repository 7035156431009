import React from 'react';
import { COLOR } from '../../constants/common';

export const TextInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
    <clipPath id="text-interaction">
      <path d="m0 0h24v24h-24z" />
    </clipPath>
    <g
      clipPath="url(#text-interaction)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="m4.8 6.8c0-1.1.9-2 2-2h10.5c1.1 0 2 .9 2 2v7.5c0 1.1-.9 2-2 2h-2.6l-2.6 3-2.6-3h-2.7c-1.1 0-2-.9-2-2z"
        strokeWidth="1.5"
      />
      <path d="m9.5 11c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z" />
      <path d="m12.5 11c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z" />
      <path d="m15.5 11c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z" />
    </g>
  </svg>
);

export const NewTextInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4H20V16H5.17L4 17.17V4ZM4 2C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2H4ZM6 12H18V14H6V12ZM6 9H18V11H6V9ZM6 6H18V8H6V6Z" fill="#4392E0" />
  </svg>

);
