import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';

import { request } from '../utils/request';
import { API_ENDPOINTS, defaultPagination, INTERACTION_API_TYPE_MAP } from '../../constants/common';
import { processReply, useChangeEffect } from '../../utils/utils';
import i18n from 'i18next';
import { useUser } from '../../../models/users/withUser';
import { WithDebounce } from '../utils/withDebounce';
import { CloudIcon } from '../../ui/icons/CloudIcon';
import { ArchiveIcon } from '../../ui/icons/ArchiveIcon';
import { StarIcon } from '../../ui/icons/StarIcon';
import { WarningIcon } from '../../ui/icons/WarningIcon';
import { VictoryBadgeIcon } from '../../ui/icons/VictoryBadgeIcon';
import { useGlobal } from '../../../models/global/withGlobal';
//import window from "react-calendar-timeline/lib/resize-detector/window";

export const messagesFilters = {
  all: { value: 'all', label: i18n.t('All participations') },
  liked: { value: 'liked', icon: <StarIcon /> },
  archive: { value: 'archive', icon: <ArchiveIcon /> },
  uploaded: { value: 'uploaded', icon: <CloudIcon /> },
  moderated: { value: 'moderated', icon: <WarningIcon /> },
  trash: { value: 'archived', label: i18n.t('Trash') },
};

export const gameMessagesFilters = {
  all: { value: 'all', label: i18n.t('All participations') },
  liked: { value: 'liked', icon: <StarIcon /> },
  archive: { value: 'archive', icon: <ArchiveIcon /> },
  uploaded: { value: 'uploaded', icon: <CloudIcon /> },
  moderated: { value: 'moderated', icon: <WarningIcon /> },
  winner: { value: 'winner', icon: <VictoryBadgeIcon/>},
  trash: { value: 'archived', label: i18n.t('Trash') },
};

export const moderationFilters = {
  moderated: { value: 'moderated', label: i18n.t('All moderated messages') },
};

let messagesInterval = null;

export const WithMessages = ({
  search,
  filter,
  defaultPerPage = 50,
  interactionId,
  interactionType
}) => {
  let params = queryString.parse(window.location.search);
  const interactions_list = params.interactions_list;
  const { channel } = useUser();
  let [pagination, setPagination] = useState({
    ...defaultPagination,
    perPage: defaultPerPage,
  });
  const [interactions, setInteractions] = useState([]);
  const [counts, setCounts] = useState({});
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isAutorefresh, setAutorefresh] = useState(true);
  const [totalInteractions, setTotalInteractions] = useState(null);
  const { debounce } = WithDebounce();
  const totalInteractionsRef = useRef(totalInteractions);
  const [modalInteraction, setModalInteraction] = useState({});
  const { refresh } = useGlobal();
  totalInteractionsRef.current = totalInteractions;

  // Need ref inside setInterval
  const filterRef = useRef(filter);
  filterRef.current = filter;
  const searchRef = useRef(search);
  searchRef.current = search;
  const interactionIdRef = useRef(interactionId);
  interactionIdRef.current = interactionId;
  const interactionTypeRef = useRef(interactionType);
  interactionTypeRef.current = interactionType;
  const selectedChoiceRef = useRef(selectedChoice);
  selectedChoiceRef.current = selectedChoice;

  const fetchModalInteraction = async interactionId => {
    const { data } = await request({
      url: API_ENDPOINTS.interaction(interactionId),
    });
    setModalInteraction(processReply(data));
    return data;
  };

  const changeAutorefresh = () => {
    setAutorefresh(!isAutorefresh);
  }
  const downloadCSV = (id) => {
    if (!id)
      return;
    if (!channel) {
      return;
    }
    let { data } = request({
      url: API_ENDPOINTS.segment_export(id),
      method: 'GET',
    }).then((response) => {
      console.log(response);
      // Creating a Blob for having a csv file format
      // and passing the data with type
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob)

      // Creating an anchor(a) tag of HTML
      const a = document.createElement('a')

      // Passing the blob downloading url
      a.setAttribute('href', url)

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute('download', 'export.csv');

      // Performing a download with click
      a.click()
    })
    return ;
  }


  const fetchCounts = async () => {
    if (!channel) {
      return;
    }
    const query = { channel_id: channel.id };
    if (interactionIdRef.current) {
      query.segment_id = interactionIdRef.current;
    }
    const { data } = await request({
      url: API_ENDPOINTS.interactionCounts,
      query,
    });
    setCounts(data);
  };

  const getInteractionsQuery = (extraData) => {
    let query = {
      exclude_channel_interactions: true,
      channel_id: channel.id,
      per_page: pagination.perPage,
      page: pagination.page,
      search_text: searchRef.current || '',
      filter: filterRef.current,
      interactions_list: interactions_list || [],
    };

    if (interactionIdRef.current) {
      query.segment_id = interactionIdRef.current;
    }
    if (interactionTypeRef.current === INTERACTION_API_TYPE_MAP.game) {
      // idk why false did not work
      query.voice_only = null;
    }
    if (extraData) {
      query = { ...query, ...extraData };
    }
    if (selectedChoice) {
      query.choice_id = selectedChoiceRef.current.id;
    }

    return query;
  };

  const checkForNewInteractions = async () => {
    if (!channel) {
      return;
    }
    const { pagination: newPagination } = await request({
      url: API_ENDPOINTS.channelInteractions,
      query: getInteractionsQuery({ per_page: 0 }),
    });
    const { total } = newPagination;
    if (totalInteractionsRef.current === null) {
      return setTotalInteractions(total);
    }
    if (total !== totalInteractionsRef.current) {
      setTotalInteractions(total);
      fetchInteractions();
    }
  };

  const fetchInteractions = async () => {
    if (!channel) {
      return;
    }
    setLoading(true);

    let { data, pagination: newPagination } = await request({
      url: API_ENDPOINTS.channelInteractions,
      query: getInteractionsQuery(),
    });
    data = data.map(processReply);
    setPagination({
      ...pagination,
      ...newPagination,
    });
    setInteractions(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchInteractions();
  }, [selectedChoice]);

  const paginationClick = (value) => !isLoading && setPagination(value);

  const postInteractionToFtp = async (interactionId) => {
    const { data } = await request({
      url: API_ENDPOINTS.interactionFtp(interactionId),
      method: 'POST',
    });
    if (!data || data.message) {
      console.warn(data);
      alert(
        `Something went wrong. ${
          data.message || 'Please check channel cloud transfer configuration.'
        }`
      );
    }
  };

  const setInteractionAction = async (interaction, action) => {
    if (action === 'uploaded') {
      await postInteractionToFtp(interaction.id);
    } else {
      const body = { key: action };
      await request({
        url: API_ENDPOINTS.interactionAction(interaction.id),
        method: 'POST',
        body,
      });
    }
    fetchInteractions();
    fetchModalInteraction(interaction.id);
    fetchCounts();
  };

  const setActionForInteractions = async ({action } = {action: 'archive_all_messages'}) => {
    setLoading(true);

    let { data } = await request({
      url: API_ENDPOINTS.channelInteractionsActions,
      query: {
        ...getInteractionsQuery(),
        action: action,
      },
      method: 'POST',
    });
    console.log({
      f: 'setActionForInteractions',
      data,
    })
    setPagination({
      ...defaultPagination,
      perPage: defaultPerPage,
    });
    fetchInteractions();
    fetchCounts();
    setLoading(false);
  };

  useChangeEffect(() => {
    pagination = { ...defaultPagination, perPage: defaultPerPage };
    fetchInteractions();
    setTotalInteractions(null);
  }, [filter]);

  useChangeEffect(() => {
    pagination = { ...defaultPagination, perPage: defaultPerPage };
    debounce(fetchInteractions);
    setTotalInteractions(null);
  }, [search]);

  useChangeEffect(() => {
    fetchInteractions();
    // eslint-disable-next-line
  }, [pagination.page]);

  useEffect(() => {
    fetchInteractions();
    fetchCounts();
    clearInterval(messagesInterval);
    if (isAutorefresh) {
      messagesInterval = setInterval(checkForNewInteractions, 15000);
    }
  }, [channel, isAutorefresh]);

  useEffect(() => {
    return () => {
      clearInterval(messagesInterval);
    };
  }, []);

  useEffect(() => {
    setModalInteraction({});
  }, [refresh]);

  return {
    counts,
    messages: interactions,
    isLoading,
    isAutorefresh,
    pagination,
    selectedChoice,
    modalInteraction,
    // setters
    setSelectedChoice,
    setPagination,
    setModalInteraction,
    // methods
    fetchInteractions,
    fetchCounts,
    paginationClick,
    setInteractionAction,
    setActionForInteractions,
    changeAutorefresh,
    downloadCSV,
  };
};
