import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableWrapper,
} from '../../ui/table/Table';
import { Text } from '../../ui/text/Text';
import { useTranslation } from 'react-i18next';
import {
  COLOR,
  INTERACTION_API_TYPE_MAP,
  MP4_BACKGROUND_STR,
  STATIC_BUCKET,
} from '../../../constants/common';
import { useUser } from '../../../models/users/withUser';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { InfoIcon } from '../../ui/icons/InfoIcon';
import { Tools, ToolsWrapper } from '../../ui/table/Tools';
import { ParticipationsIcon } from '../../ui/icons/ParticipationsIcon';
import { EditIcon } from '../../ui/icons/EditIcon';
import { ArchiveIcon } from '../../ui/icons/ArchiveIcon';
import { DeleteIcon } from '../../ui/icons/DeleteIcon';
import { DuplicateIcon } from '../../ui/icons/DuplicateIcon';
import { Pointer } from '../../ui/box/Pointer';
import { useModal } from '../../models/global/withModal';
import ReactTooltip from 'react-tooltip';
import { InteractionResults } from './InteractionResults';
import { TextTooltip } from '../../ui/tooltip/TextTooltip';
import { FlexCenter } from '../../ui/box/FlexCenter';
import { QRCodeIcon } from '../../ui/icons/QRCodeIcon';
import QRCode from "react-qr-code";
import { isToDisplay } from '../../utils/utils';

const InteractionBg = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${COLOR.white};
  background-image: url(${({ background }) => background || ''});
  box-sizing: border-box;
  position: relative;
  img {
    z-index: 1;
  }
`;

const VideoInteractionBg = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const InteractionType = styled(Flex)`
  align-items: center;
  img,
  svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
`;

const ImageCell = styled(Cell)`
  padding: 2.5px 0;
  cursor: ${props => props.pointer ? 'pointer': 'default'};
`;

const AvailabilityIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ color }) => color};
`;

const QRCodeIconWrapper = styled(Pointer)`
  svg {
    width: 18px;
    height: 18px;
  }
  padding-left: 8px;
`
export const goToParticipation = (user, interaction) => {
  if(!isToDisplay(user, "interaction-sous-menu-actions")) {
    window.location.href = `/participations/${interaction.id}`;
  }
}
export const downloadQrCode = (qrCodeId, format = 'svg') => {
  const svg = document.getElementById(qrCodeId);

  if (format === 'svg') {
    const blob = new Blob([svg.outerHTML.toString()]);
    const element = document.createElement("a");
    element.download = "interaction-qr-code.svg";
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
  }

  if (format === 'png') {
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }
}

export const Interactions = ({ interactions, updateInteraction, duplicateInteraction, user }) => {
  const { t } = useTranslation();
  const { channel } = useUser();
  const history = useHistory();
  const { openModal } = useModal();

  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {isToDisplay(user, "interaction-visual") &&
              <TableHeaderCell>
                <Text>{t('Visual')}</Text>
              </TableHeaderCell>
            }
            {isToDisplay(user, "interaction-text") &&
              <TableHeaderCell>
                <Text>{t('Interaction')}</Text>
              </TableHeaderCell>
            }
            {isToDisplay(user, "interaction-type") &&
              <TableHeaderCell>
                <Text>{t('Type')}</Text>
              </TableHeaderCell>
            }
            {isToDisplay(user, "interaction-availability") &&
              <TableHeaderCell>
                <Text>{t('Availability')}</Text>
              </TableHeaderCell>
            }
            {isToDisplay(user, "interaction-participants") &&
              <TableHeaderCell>
                <Text center>{t('Participants')}</Text>
              </TableHeaderCell>
            }
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {interactions &&
            interactions.map((interaction) => {
              const background =
                interaction.background_url ||
                (channel ? channel.background_url : '');
              const isVideoBackground =
                background &&
                background.includes(MP4_BACKGROUND_STR) &&
                !background.includes(STATIC_BUCKET);
              return (
                <Row key={interaction.id}>
                  {isToDisplay(user, "interaction-visual") &&
                    <ImageCell onClick={e => goToParticipation(user, interaction)} pointer={!isToDisplay(user, "interaction-sous-menu-actions")}>
                      {isVideoBackground ? (
                        <InteractionBg>
                          <VideoInteractionBg
                            src={background}
                            muted
                            autoPlay
                            loop
                          />
                        </InteractionBg>
                      ) : (
                        <InteractionBg background={background} />
                      )}
                    </ImageCell>
                  }
                  {isToDisplay(user, "interaction-text") &&
                    <Cell p={'0 20px'}>
                      <ToolsWrapper onClick={e => goToParticipation(user, interaction)} pointer={!isToDisplay(user, "interaction-sous-menu-actions")}>
                        {isToDisplay(user, "interaction-sous-menu-actions") &&
                          <Tools>
                            {isToDisplay(user, "interaction-action-goto-participations") &&
                              <Link
                                to={`/participations/${interaction.id}`}
                                data-tip={t('Participations')}
                                data-for={'text-tooltip'}
                              >
                                <ParticipationsIcon />
                              </Link>
                            }
                            {isToDisplay(user, "interaction-action-edit") &&
                              <Pointer
                                onClick={() =>
                                  interaction.status === 'draft'
                                    ? openModal('interaction', interaction)
                                    : history.push(`/interactions/${interaction.id}`)
                                }
                                data-tip={t('Edit')}
                                data-for={'text-tooltip'}
                              >
                                <EditIcon />
                              </Pointer>
                            }
                            {isToDisplay(user, "interaction-action-archived") &&
                              <Pointer
                                onClick={() =>
                                  updateInteraction(interaction.id, {
                                    status: 'archived',
                                  })
                                }
                                data-tip={t('Archive')}
                                data-for={'text-tooltip'}
                              >
                                <ArchiveIcon color={'#A1A3A3'} active />
                              </Pointer>
                            }
                            {isToDisplay(user, "interaction-action-trash") &&
                              <Pointer
                                onClick={() =>
                                  updateInteraction(interaction.id, {
                                    status: 'trash',
                                  })
                                }
                                data-tip={t('Delete')}
                                data-for={'text-tooltip'}
                              >
                                <DeleteIcon />
                              </Pointer>
                            }
                            {isToDisplay(user, "interaction-action-duplicate") &&
                              <Pointer
                                onClick={() =>
                                  duplicateInteraction(interaction.id)
                                }
                                data-tip={t('Duplicate')}
                                data-for={'text-tooltip'}
                              >
                                <DuplicateIcon />
                              </Pointer>
                            }

                          </Tools>
                        }
                        <Text semiBold>{interaction.title}</Text>
                        {isToDisplay(user, "interaction-description") &&
                          <Text>{interaction.text}</Text>
                        }
                      </ToolsWrapper>
                    </Cell>
                  }
                  {isToDisplay(user, "interaction-type") &&
                    <Cell>
                      <InteractionType>
                        <Text semiBold capital>
                          {t(interaction.interactionType.label)}
                        </Text>
                      </InteractionType>
                    </Cell>
                  }
                  {isToDisplay(user, "interaction-availability") &&
                    <Cell>
                      <Flex mb={'3px'} alignItems={'center'}>
                        <AvailabilityIndicator
                          color={interaction.availability.color}
                        />
                        <Text semiBold ml={'5px'} capital>
                          {t(interaction.availability.label)}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text
                          as={'a'}
                          href={interaction.shareUrl}
                          target="_blank"
                          theme={'link'}
                          rel={'noreferrer'}
                        >
                          {interaction.shareUrl}
                        </Text>
                        <QRCodeIconWrapper
                          as={'a'}
                          target={'_blank'}
                          href={'qr-code?qrData=' + interaction.shareUrl}
                          onClick={() => downloadQrCode(`qr-code-${interaction.id}`)}
                        >
                          <QRCodeIcon />
                          <Box display={'none'}>
                            <QRCode
                              id={`qr-code-${interaction.id}`}
                              value={interaction.shareUrl}
                            />
                          </Box>
                        </QRCodeIconWrapper>
                      </Flex>
                      <Text mt={'3px'}>
                        {t('Start')} : {interaction.startDate}
                      </Text>
                      <Text>
                        {t('End')} : {interaction.endDate || t('Indefinite')}
                      </Text>
                    </Cell>
                  }
                  {isToDisplay(user, "interaction-participants") &&
                    <Cell>
                      <FlexCenter>
                        <Text
                          as={Link}
                          to={`/participations/${interaction.id}`}
                          theme={'active'}
                          semiBold
                        >
                          {interaction.answers_count}
                        </Text>
                        <Pointer
                          mt={'3px'}
                          ml={'8px'}
                          data-tip={interaction.id}
                          data-for={'interaction-results'}
                        >
                          <InfoIcon />
                        </Pointer>
                      </FlexCenter>
                    </Cell>
                  }
                </Row>
              );
            })}
        </TableBody>
      </Table>
      <TextTooltip />
      <ReactTooltip
        id={'interaction-results'}
        type={'light'}
        backgroundColor={COLOR.transparent}
        offset={{ left: -25 }}
        getContent={(interactionId) => (
          <InteractionResults interactionId={interactionId} tooltip />
        )}
      />
    </TableWrapper>
  );
};
