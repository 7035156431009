import React from 'react';
import { COLOR } from '../../constants/common';

export const StarIcon = ({ active, color = COLOR.blue }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.87"
    height="20"
    viewBox="0 0 20.87 20"
  >
    <defs>
      <clipPath id="star-icon-clip-path">
        <path d="M0 0H20.87V20H0z" fill={active ? color : 'none'} />
      </clipPath>
    </defs>
    <g clipPath="url(#star-icon-clip-path)">
      <path
        stroke={color}
        fill={active ? color : 'none'}
        d="M18.629 6.764a.991.991 0 0 0-.855-.682l-5.391-.49L10.252.6a.993.993 0 0 0-1.826 0L6.295 5.592.9 6.081a.994.994 0 0 0-.561 1.737l4.075 3.574-1.2 5.293a.991.991 0 0 0 1.476 1.073l4.65-2.779 4.648 2.779a.993.993 0 0 0 1.477-1.073l-1.2-5.293 4.075-3.573a.993.993 0 0 0 .291-1.055"
        transform="translate(1 1.101)"
      />
    </g>
  </svg>
);
