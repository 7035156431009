import React from 'react';
import { COLOR } from '../../constants/common';

export const CloudIcon = ({ active, color = COLOR.blue2 }) => (
  <svg
    height="21"
    viewBox="0 0 31 21"
    width="31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24.187 11.55a9.366 9.366 0 0 0 -17.5-2.5 7.5 7.5 0 0 0 .813 14.95h16.25a6.232 6.232 0 0 0 .438-12.45zm-6.687 3.7v5h-5v-5h-3.75l1.373-1.373 2.237-2.237 2.64-2.64 1.336 1.336 2.723 2.723 2.191 2.191z"
      fill={active ? color : 'none'}
      stroke={color}
      transform="translate(.5 -3.5)"
    />
  </svg>
);
